
import { defineComponent } from 'vue'
import Logo from '@/assets/svg/logo.svg?inline'
import Burger from '@/assets/svg/burger.svg?inline'
import Letter from '@/assets/svg/letter.svg?inline'
import Mail from '@/assets/svg/mail.svg?inline'

export default defineComponent({
  name: 'Header',
  data () {
    return {
      mobileMenu: false,
      mailbox: false,
      mailboxActive: false,
      openBurger: false,
      balance: 0,
      letterData: {},
      usersData: {},
      letter: null,
      cameLetter: false,
      flag: false
    }
  },
  components: {
    Logo,
    Burger,
    Letter,
    Mail
  },
  watch: {
    handleLogout: {
      deep: true,
      handler () {
        setTimeout(function () {
          localStorage.removeItem('user')
          window.location.replace('/login')
        }, 259200000)
      },
      immediate: true
    }
  },
  channels: {
    ParentsChannel: {
      connected () {
        console.log('I am connected.')
      },
      response (data) {
        console.log(data, 'data')
      }
    }
  },
  created () {
    setInterval(() => { this.getBalance() }, 20000)
    setInterval(() => { this.getUsers() }, 60000)
  },
  mounted () {
    this.$cable.subscribe({
      channel: {
        command: 'subscribe',
        identifier: {
          channel: 'ParentsChannel'
        },
        message: {
          event: 'balance_changed'
        },
        auth: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('user')
          }
        }
      }
    })
    this.getBalance()
    this.getUsers()
    this.getUserLetter()
    // this.putUserFlag()
  },
  methods: {
    getBalance () {
      this.$store.dispatch('getPayments/getBalance').then((res) => {
        this.balance = res.data.coins
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    getUsers () {
      this.$store.dispatch('getPayments/getUsers').then((res) => {
        this.usersData = res.data.data
        this.flag = this.usersData.some((item) => item.attributes.flag === true)
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    putUserFlag () {
      this.$store.dispatch('getPayments/putUserFlag').then((res) => {
        console.log(res, 'flag')
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    getUserLetter () {
      this.$store.dispatch('getPayments/getLetters').then((res) => {
        this.letter = res.data.data.length
        this.letterData = res.data.data
        if (this.letter.length !== 0) {
          this.cameLetter = true
        }
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    viewLetterRequest () {
      this.$store.dispatch('getPayments/viewLetterRequest', {
        letter_requested: true
      }).then((res) => {
        this.letter = 0
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    openMobMenu () {
      this.mobileMenu = true
    },
    closeMobMenu () {
      this.mobileMenu = false
    },
    handleLogout () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login')
      })
    },
    openMailbox () {
      this.mailbox = true
      this.viewLetterRequest()
    },
    closeMailbox () {
      this.mailbox = false
      this.mailboxActive = false
    },
    openMailboxActive () {
      this.mailboxActive = true
      this.viewLetterRequest()
    },
    openBurgerMenu () {
      if (this.openBurger === false) {
        this.openBurger = true
      } else this.openBurger = false
    }
  }
})
